import { mapActions, mapGetters, mapMutations } from 'vuex'
import validationMixin from '@/mixins/validation'
import { debounce, snakeCase } from 'lodash'
import { userCourseListTableHead } from '../../helpers'

export default {
  name: 'user-admins-courses',
  mixins: [validationMixin],
  data () {
    return {
      valid: false,
      validUpdateProgressForm: false,
      page: 1,
      showDialogCourse: false,
      showRemoveDialog: false,
      showDialogUpdateProgress: false,
      validFormCourse: false,
      deletedItemData: null,
      editedItem: null,

      keywords: '',
      payload: {
        courseId: '',
        packageId: ''
      },
      progressForm: {
        userId: this.$route.params.id,
        moduleId: '',
        packageId: '',
        courseId: '',
        courseUserId: '',
        id: ''
      },

      search: null,
      searchInput: null
    }
  },
  computed: {
    ...mapGetters({
      userCourses: 'userCourses/userCourses',
      userCoursesLength: 'userCourses/userCoursesLength',
      isUserCoursesLoading: 'userCourses/isUserCoursesLoading',
      limit: 'userCourses/limit',
      skip: 'userCourses/skip',
      isLoading: 'userCourses/isLoading',

      courses: 'userCourses/courses',
      isCoursesLoading: 'userCourses/isCoursesLoading',
      packagesCourse: 'userCourses/packagesCourse',
      isPackagesCourseLoading: 'userCourses/isPackagesCourseLoading',

      courseModules: 'courses/courseModules',
      courseModulesLoading: 'courses/courseModulesLoading',
      courseMaterials: 'courses/courseMaterials',
      courseMaterialsLoading: 'courses/courseMaterialsLoading'
    }),
    userCourseListTableHead
  },
  created () {
    if (!this.userCourses.length) {
      this.fetchUserCourses({
        userId: this.$route.params.id,
        keywords: this.keywords
      })
    }
    if (this.userCourses) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
  },
  methods: {
    ...mapActions({
      fetchCourses: 'userCourses/GET_COURSES',
      fetchPackages: 'userCourses/PACKAGES_GET_LIST',
      fetchUserCourses: 'userCourses/GET_USER_COURSES',
      fetchCourseModules: 'courses/GET_COURSE_MODULES',
      fetchCourseMaterials: 'courses/GET_COURSE_MATERIALS',
      remove: 'userCourses/DELETE',
      update: 'userCourses/UPDATE',
      updateProgress: 'userCourses/UPDATE_PROGRESS',
      create: 'userCourses/CREATE'
    }),
    ...mapMutations({
      changeSkip: 'userCourses/USER_COURSES_CHANGE_SKIP',
      setUserCourses: 'userCourses/SET_USER_COURSES',
      setCourses: 'userCourses/SET_COURSES',
      setPackages: 'userCourses/PACKAGES_SET_LIST'
    }),

    changeInputCourse: debounce(function (val) {
      if (!val) return
      if (this.keywords !== val) {
        this.keywords = val
        this.fetchCourses({
          keywords: val,
          id: this.payload.courseId
        })
      }
    }, 500),
    autoCompleteChanged (e) {
      this.setPackages([])
      this.payload.courseId = e.id
      this.search = e
      this.fetchPackages({ courseId: e.id })
    },
    // end //
    changeDialogCourse (item = null, status = false) {
      this.setPackages([])
      this.showDialogCourse = status
      this.editedItem = item
      if (status) {
        if (item) {
          this.payload.packageId = item.package.data.id
          this.fetchPackages({ courseId: item.course.data.id })
        } else {
          this.setCourses([])
          this.fetchCourses({ keywords: '' })
        }
      } else {
        this.payload.courseId = ''
        this.payload.packageId = ''
        this.search = null
        this.searchInput = null
      }
    },

    removeItem () {
      this.remove({
        userId: this.$route.params.id,
        id: this.deletedItemData.id
      }).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeDialogDelete (item = null) {
      this.showRemoveDialog = !!item
      this.deletedItemData = item
    },
    changeDialogUpdateProgress (status = false, item = null) {
      this.showDialogUpdateProgress = status
      this.progressForm.userId = this.$route.params.id
      if (item) {
        // eslint-disable-next-line camelcase
        const { id, module_id } = item.currentMaterial.data
        this.progressForm.courseUserId = item.id
        this.progressForm.courseId = item.course_id
        this.progressForm.moduleId = module_id
        this.progressForm.id = id
        this.progressForm.packageId = item.package_id

        if (item.currentMaterial.data.hasOwnProperty('id')) {
          this.fetchCourseMaterials(this.progressForm)
        }
        this.fetchCourseModules(this.progressForm)
      } else {
        Object.keys(this.progressForm).forEach(e => {
          this.progressForm[e] = ''
        })
      }
    },
    updateProgressCourse () {
      this.$refs.updateProgressForm.validate()
      if (this.validUpdateProgressForm) {
        this.progressForm.lesson_id = this.progressForm.id
        this.updateProgress(this.progressForm).then(() => {
          this.$toasted.success(this.$t('success_updated'))
          this.changeDialogUpdateProgress()
          this.fetchUserCourses({
            userId: this.$route.params.id,
            keywords: this.keywords
          })
        })
      }
    },
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchUserCourses({ userId: this.$route.params.id })
    },
    createRequestPayload () {
      const formData = {}
      for (const i in this.payload) {
        formData[snakeCase(i)] = this.payload[i]
      }
      formData.userId = this.$route.params.id
      if (this.editedItem) {
        formData._method = 'patch'
        formData.id = this.editedItem.id
      }
      return formData
    },
    submit () {
      this.$refs.formCourse.validate()
      if (this.validFormCourse) {
        if (this.editedItem) {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'))
            this.changeDialogCourse()
          })
        } else {
          this.create(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_created'))
            this.changeDialogCourse()
          })
        }
      }
    }
  },
  destroyed () {
    this.setUserCourses([])
  }
}
